import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";

const Terms = () => (
  <Layout title={"Terms and Conditions"}>
    <article>
      <HeaderTitle overline={"Last updated: June 28, 2022"}>
        <span lang={"de"}>Allgemeine Geschäftsbedingungen ("AGB")</span>
      </HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
          lang={"de"}
        >
          <p>
            Diese Allgemeinen Geschäftsbedingungen ("Bedingungen", "AGB") regeln
            den auf der Website{" "}
            <a href={"https://www.userbrain.com"}>https://www.userbrain.com</a>{" "}
            abgeschlossen Vertrag mit Userbrain GmbH ("uns", "wir" oder "unser")
            über die Inanspruchnahme von User-Tests („Dienst/e“).
          </p>
          <p>
            Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig
            durch, bevor Sie den Vertrag abschließen bzw den Dienst nutzen.
          </p>
          <p>
            Ihr Zugang zu und Ihre Nutzung des Dienstes setzt voraus, dass Sie
            die vorliegenden Bedingungen akzeptieren und einhalten. Diese
            Bedingungen gelten für alle Vertragspartner, Besucher, Nutzer und
            andere, die auf den Dienst zugreifen oder ihn nutzen.
          </p>
          <p>
            <strong>
              Durch den Vertragsabschluss oder den Zugriff auf den Dienst oder
              dessen Nutzung erklären Sie sich mit diesen Bedingungen
              einverstanden. Wenn Sie mit irgendeinem Teil der Bedingungen nicht
              einverstanden sind, dürfen Sie den Vertrag nicht abschließen
              und/oder nicht auf den Dienst zugreifen.
            </strong>
          </p>

          <h2>Abonnements</h2>
          <p>
            Einige Teile des Dienstes werden auf Abonnementbasis abgerechnet
            ("Abonnement(e)"). Die Abrechnung erfolgt im Voraus auf
            wiederkehrender und regelmäßiger Basis ("Abrechnungszyklus"). Die
            Abrechnungszyklen werden entweder auf monatlicher oder jährlicher
            Basis festgelegt, je nachdem, welche Art von Abonnementplan Sie beim
            Kauf eines Abonnements auswählen.
          </p>
          <p>
            Am Ende eines jeden Abrechnungszeitraums verlängert sich Ihr
            Abonnement automatisch zu denselben Bedingungen, sofern Sie es nicht
            kündigen oder die Userbrain GmbH es nicht kündigt. Sie können die
            Verlängerung Ihres Abonnements entweder über Ihre
            Online-Kontoverwaltung oder durch Kontaktaufnahme mit dem
            Userbrain-Kundensupport kündigen (siehe dazu auch weiter unten zu
            Punkt Kündigung).
          </p>
          <p>
            Je nach Abonnement erhalten Sie entweder monatlich oder jährlich
            eine bestimmte Anzahl an Testern für Tests („Kontingent“). Solange
            der Vertrag aufrecht ist, können Sie nicht verbrauchtes Kontingent
            nach Ihrem zeitlichen Ermessen nutzen und verbrauchen.{" "}
            <strong>
              Wenn Sie Ihr Abonnement kündigen, können Sie Ihr vorhandenes
              Kontingent noch bis zum Ende des Abonnementzeitraums nutzen und
              verbrauchen. Nach Ablauf des Abonnementzeitraums verfällt nicht
              verbrauchtes Kontingent unwiderruflich ohne Anspruch auf Ersatz.
            </strong>{" "}
            Dies gilt nicht für Credits, die Sie zusätzlich zu einem Abonnement
            gesondert kaufen – derartige Credits verfallen bei einer Kündigung
            nicht.
          </p>
          <p>
            Eine gültige Zahlungsmethode, einschließlich Kreditkarte oder
            PayPal, ist erforderlich, um die Zahlung für Ihr Abonnement
            abzuwickeln. Sie sind verpflichtet, der Userbrain GmbH korrekte und
            vollständige Rechnungsdaten wie Name, Adresse, Bundesland,
            Postleitzahl, Telefonnummer und eine gültige Zahlungsmethode
            anzugeben. Mit der Übermittlung dieser Zahlungsinformationen
            ermächtigen Sie die Userbrain GmbH automatisch, alle über Ihr Konto
            anfallenden Abonnementgebühren von diesen Zahlungsmitteln
            abzubuchen.
          </p>
          <p>
            Sollte die automatische Abrechnung aus irgendeinem Grund ausbleiben,
            stellt die Userbrain GmbH eine elektronische Rechnung aus, in der
            Sie aufgefordert werden, innerhalb einer bestimmten Frist den vollen
            Betrag für den auf der Rechnung angegebenen Abrechnungszeitraum
            manuell zu bezahlen.
          </p>

          <h2>Kostenlose Testversion</h2>
          <p>
            Die Userbrain GmbH kann nach eigenem Ermessen ein Abonnement mit
            einer kostenlosen Testphase für einen begrenzten Zeitraum anbieten
            ("kostenlose Testphase").
          </p>
          <p>
            Sie müssen möglicherweise Ihre Rechnungsdaten eingeben, um sich für
            die kostenlose Testversion anzumelden.
          </p>
          <p>
            Wenn Sie bei der Anmeldung für den kostenlosen Testzeitraum Ihre
            Rechnungsdaten eingeben, werden Ihnen die Kosten erst nach Ablauf
            des kostenlosen Testzeitraums von der Userbrain GmbH in Rechnung
            gestellt. Diesfalls werden Ihnen am letzten Tag des kostenlosen
            Testzeitraums, sofern Sie Ihr Abonnement nicht gekündigt haben,
            automatisch die entsprechenden Abonnementgebühren für die von Ihnen
            gewählte Art des Abonnements in Rechnung gestellt.
          </p>
          <p>
            Die Userbrain GmbH behält sich das Recht vor, jederzeit (i) die
            Bedingungen des kostenlosen Testangebots zu ändern oder (ii) das
            kostenlose Testangebot zu beenden.
          </p>

          <h2>Entgeltänderungen</h2>
          <p>
            Die Userbrain GmbH kann das Entgelt für die Abonnements
            („Abonnementgebühren“) nach eigenem Ermessen und zu jeder Zeit
            ändern. Jede Änderung der Abonnementgebühren wird zum Ende des
            aktuellen Abrechnungszeitraums wirksam.
          </p>
          <p>
            Die Userbrain GmbH wird Sie mit einer angemessenen Frist über eine
            Änderung der Abonnementgebühren informieren, um Ihnen die
            Möglichkeit zu geben, Ihr Abonnement vor Inkrafttreten der Änderung
            zu kündigen.
          </p>
          <p>
            Ihre fortgesetzte Nutzung des Dienstes nach Inkrafttreten der
            Änderung der Abonnementgebühr gilt als Ihre Zustimmung zur Zahlung
            des geänderten Abonnementgebührenbetrags.
          </p>

          <h2>Erstattungen</h2>
          <p>
            Bestimmte Rückerstattungsanträge für Abonnements können von der
            Userbrain GmbH von Fall zu Fall geprüft und nach eigenem Ermessen
            gewährt werden.
          </p>

          <h2>Inhalte</h2>
          <p>
            Unser Dienst ermöglicht es Ihnen, bestimmte Informationen, Texte,
            Grafiken, Videos oder andere Materialien ("Inhalte") zu posten, zu
            verlinken, zu speichern, zu teilen oder anderweitig verfügbar zu
            machen. Sie sind für die Inhalte, die Sie in den Dienst einstellen,
            selbst verantwortlich in jedem Bereich und Umfang, von der
            Rechtmäßigkeit bis hin zur Zweckmäßigkeit.
          </p>

          <p>
            Durch das Einstellen von Inhalten in den Dienst gewähren Sie uns das
            Recht und die Lizenz sowie alle sonst notwendigen Rechte, diese
            Inhalte im und über den Dienst zu verwenden, zu ändern, auszuführen,
            anzuzeigen, zu reproduzieren und zu verbreiten. Sie verlieren
            dadurch nicht das Recht an Inhalten, die Sie im oder über den Dienst
            einreichen, veröffentlichen oder anzeigen; vielmehr bleiben Sie für
            Rechtmäßigkeit sowie den Schutz dieser Rechte verantwortlich.
          </p>
          <p>
            Sie erklären und garantieren, dass: (i) Ihnen am Inhalt
            entsprechende Rechte zukommen (Sie sind Eigentümer oder Sie haben
            das Recht, ihn zu nutzen und uns die in diesen Bedingungen
            vorgesehenen Rechte und Lizenzen zu gewähren), und (ii) die
            Veröffentlichung Ihres Inhalts im oder über den Dienst nicht gegen
            die Datenschutzrechte, Veröffentlichungsrechte, Urheberrechte,
            Vertragsrechte oder andere Rechte einer Person oder sonstige Gesetze
            verstößt.
          </p>

          <h2>Konten</h2>
          <p>
            Wenn Sie ein Konto bei uns erstellen, müssen Sie uns Informationen
            zur Verfügung stellen, die korrekt, vollständig und aktuell sind.
            Wenn Sie dies nicht tun, stellt dies einen Verstoß gegen die
            Bedingungen dar, der zur sofortigen Kündigung Ihres Kontos bei
            unserem Service führen kann.
          </p>
          <p>
            Sie sind verantwortlich für den Schutz des Passworts, das Sie für
            den Zugang zum Dienst verwenden, sowie für alle Aktivitäten oder
            Handlungen, die unter Ihrem Passwort durchgeführt werden, unabhängig
            davon, ob Ihr Passwort zu unserem Dienst oder zu einem Dienst eines
            Dritten gehört.
          </p>
          <p>
            Sie verpflichten sich, Ihr Passwort nicht an Dritte weiterzugeben.
            Sie müssen uns sofort benachrichtigen, wenn Sie von einer Verletzung
            der Sicherheit oder einer unbefugten Nutzung Ihres Kontos erfahren.
          </p>

          <h2>Geheimhaltungsverpflichtung</h2>
          <p>
            Userbrain GmbH verpflichtet sich während der Dauer des
            Vertragsverhältnisses und unbefristet danach, Stillschweigen über
            sämtliche Betriebs- und Geschäftsgeheimnisse des Kunden zu bewahren,
            sowie überhaupt alle betrieblichen und geschäftlichen
            Angelegenheiten des Kunden geheim zu halten und ausschließlich für
            Zwecke der Gesellschaft zu verwenden. Als Geschäftsgeheimnis gelten
            alle unternehmensbezogenen Tatsachen technischer oder kommerzieller
            Art, die Userbrain GmbH im Rahmen der Tätigkeit für die Gesellschaft
            bekannt werden, sowie alle vertraulichen geschäftlichen und/oder
            betrieblichen Vorgänge und Einrichtungen.
          </p>

          <h2>Geistiges Eigentum</h2>
          <p>
            Die Dienste und der Service und seine ursprünglichen Inhalte (mit
            Ausnahme der von Benutzern bereitgestellten Inhalte), Features und
            Funktionen sind und bleiben das ausschließliche Eigentum der
            Userbrain GmbH und ihrer Lizenzgeber. Der Service ist durch
            Urheberrecht, Markenrecht und andere Gesetze sowohl in Österreich
            als auch im Ausland geschützt. Unsere Marken und Markenzeichen
            dürfen ohne vorherige schriftliche Zustimmung der Userbrain GmbH
            nicht in Verbindung mit einem Produkt oder einer Dienstleistung
            verwendet werden.
          </p>

          <h2>Links zu anderen Websites</h2>
          <p>
            Unser Service kann Links zu Websites oder Diensten Dritter
            enthalten, die nicht im Besitz oder unter der Kontrolle der
            Userbrain GmbH sind.
          </p>
          <p>
            Die Userbrain GmbH hat keine Kontrolle über die Inhalte,
            Datenschutzrichtlinien oder Praktiken von Websites oder Diensten
            Dritter und übernimmt keine Verantwortung für diese. Sie erkennen
            ferner an, dass die Userbrain GmbH weder direkt noch indirekt für
            Schäden oder andere Nachteile verantwortlich oder haftbar ist, die
            durch die Nutzung von oder im Zusammenhang mit der Nutzung von oder
            dem Vertrauen auf solche Inhalte, Waren oder Dienstleistungen, die
            auf oder über solche Websites oder Dienstleistungen verfügbar sind,
            verursacht werden oder deren Verursachung behauptet wird.
          </p>
          <p>
            Wir raten Ihnen dringend, die Geschäftsbedingungen und
            Datenschutzrichtlinien aller Websites oder Dienste Dritter, die Sie
            besuchen, zu lesen.
          </p>

          <h2>Kündigung</h2>
          <p>
            Wir können Ihr Konto sofort und ohne vorherige Ankündigung oder
            Haftung kündigen oder aussetzen, insbesondere, wenn Sie gegen diese
            Bedingungen verstoßen.
          </p>
          <p>
            Sie können Ihr Konto jederzeit über Ihr Userbrain-Dashboard oder
            mittels Nachricht an{" "}
            <a href={"mailto:support@userbrain.com"}>support@userbrain.com</a>{" "}
            kündigen. Der Vertrag wird mit der Kündigung mit sofortiger Wirkung
            wechselseitig aufgehoben. Sie bleiben aber zur Einhaltung aller
            Teile dieser Vereinbarung, von denen vernünftigerweise angenommen
            werden kann, dass sie die Gültigkeit der Vereinbarung überdauern,
            auch nach der Beendigung der Vereinbarung in vollem Umfang zur
            Einhaltung verpflichtet und an diese Bestimmungen gebunden.
          </p>
          <p>
            Nach der Kündigung können Sie bestimmte Dienste der Plattform
            womöglich nicht mehr vollständig nutzen bzw auf die Plattform
            zugreifen.
          </p>
          <p>
            <strong>
              Wenn Sie Ihr Abonnement kündigen, können Sie Ihr vorhandenes
              Kontingent noch bis zum Ende des Abonnementzeitraums nutzen und
              verbrauchen. Nach Ablauf des Abonnementzeitraums verfällt nicht
              verbrauchtes Kontingent unwiderruflich ohne Anspruch auf Ersatz.
            </strong>{" "}
            Dies gilt nicht für Credits, die Sie zusätzlich zu einem Abonnement
            gesondert kaufen – derartige Credits verfallen bei einer Kündigung
            nicht.
          </p>

          <h2>Einschränkung der Haftung</h2>
          <p>
            Weder die Userbrain GmbH noch ihre Geschäftsführer, Mitarbeiter,
            Partner, Vertreter, Lieferanten oder verbundenen Unternehmen haften
            für direkte oder indirekte Schäden, Zufall sowie Folgeschäden,
            einschließlich, aber nicht beschränkt auf entgangenen Gewinn,
            Datenverlust, Nutzungsausfall, Beeinträchtigung des Firmenwerts oder
            andere immaterielle Verluste, die sich ergeben aus (i) Ihrem Zugriff
            auf die Dienste oder deren Nutzung oder der Unmöglichkeit, auf die
            Dienste zuzugreifen oder diese zu nutzen; (ii) dem Verhalten oder
            dem Inhalt von Dritten in den Diensten; (iii) jeglichem Inhalt, der
            über den Dienst erhalten wurde; und (iv) unbefugtem Zugriff, Nutzung
            oder Veränderung Ihrer Übertragungen oder Inhalte, unabhängig davon,
            ob diese auf Gewährleistung, Vertrag, unerlaubter Handlung
            (einschließlich Fahrlässigkeit) oder Gesetz beruhen, unabhängig
            davon, ob wir über die Möglichkeit solcher Schäden informiert wurden
            oder nicht, und selbst wenn sich herausstellt, dass ein hierin
            festgelegtes Rechtsmittel seinen wesentlichen Zweck verfehlt hat.
          </p>
          <p>
            Userbrain verpflichtet Tester, die unabhängige und selbständige
            Auftragnehmer sind, durch die Zustimmung der Tester zu den AGB
            (abrufbar unter{" "}
            <a href={"https://tester.userbrain.com/terms"}>
              https://tester.userbrain.com/terms
            </a>
            ) in Punkt 8. in umfassender Weise wie folgt zur Geheimhaltung: "Sie
            stimmen zu, dass alle Tests vertraulich sind und Sie alle Tests auf
            die gleiche Weise behandeln werden, wie Sie Ihre eigenen
            vertraulichen Informationen behandeln würden. Sie stimmen zu, dass
            Sie keine Tests, Aufzeichnungen oder damit verbundene Informationen
            verwenden, oder offenlegen oder sonst in den Kenntnisbereich von
            Dritten bringen, es sei denn, diese Informationen sind öffentlich
            bekannt, es sei denn, es wurde ausdrücklich von Userbrain genehmigt.
            Diese Verpflichtung gilt während und unbefristet auch nach
            Vertragsdauer. Bei einem Verstoß gegen die
            Geheimhaltungsverpflichtung verpflichtet sich der Benutzer zur
            Zahlung einer verschuldensunabhängigen und vom Vorliegen eines
            konkreten Schadens unabhängigen Konventionalstrafe in Höhe von - im
            beiderseitigen Einvernehmen pauschaliert - USD 5.000,--. Diese
            Konventionalstrafe wird mit dem Zeitpunkt der Kenntnis des Verstoßes
            durch Userbrain zur Zahlung fällig. Darüberhinausgehende
            Schadenersatzansprüche oder sonstige Ansprüche von Userbrain oder
            Dritten bleiben davon unberührt. Die Angemessenheit dieser
            Konventionalstrafe wird ausdrücklich anerkannt und unterliegt nicht
            dem richterlichen Mäßigungsrecht. Sie erklären sich damit
            einverstanden, dass Sie gegebenenfalls eine gesonderte
            Geheimhaltungsvereinbarung unterzeichnen für den Fall, dass dies von
            Userbrain oder einem Kunden von Userbrain verlangt wird." Userbrain
            kann aus praktischen Gründen die tatsächliche Einhaltung dieser
            Verpflichtung durch den Tester nicht kontrollieren und insoweit auch
            keine Haftung dafür übernehmen. Für den Fall einer Verletzung durch
            den Tester hat sich der Kunde unmittelbar gegenüber dem Tester
            schadlos zu halten.
          </p>

          <h2>Haftungsausschluss</h2>
          <p>
            Wir geben unser Bestes so schnell wie möglich passende Tester für
            die eingestellte Zielgruppe zu rekrutieren. Eine Garantie zur
            Lieferung von Testern können wir jedoch – vor allem bei der
            Verwendung von eigener Auswahl-Kriterien mittels des „Screening
            Features" – nicht bieten.
          </p>
          <p>
            Die Nutzung des Dienstes erfolgt auf Ihr eigenes Risiko. Der Dienst
            wird ohne jegliche ausdrückliche oder stillschweigende
            Gewährleistung zur Verfügung gestellt, einschließlich, aber nicht
            beschränkt auf stillschweigende Gewährleistungen der Nützlichkeit
            oder der Eignung für einen bestimmten Zweck, der Nichtverletzung von
            Rechten Dritter etc.
          </p>
          <p>
            Die Userbrain GmbH, ihre Tochtergesellschaften, verbundenen
            Unternehmen und ihre Lizenzgeber gewährleisten nicht, dass a) der
            Service ununterbrochen, sicher oder zu einem bestimmten Zeitpunkt
            oder an einem bestimmten Ort verfügbar ist; b) Fehler oder Mängel
            behoben werden; c) der Service frei von Viren oder anderen
            schädlichen Komponenten ist; oder d) die Ergebnisse der Nutzung des
            Service Ihren Anforderungen entsprechen.
          </p>

          <h2>Geltendes Recht und Gerichtsstand</h2>
          <p>
            Diese Bedingungen unterliegen den Gesetzen Österreichs unter
            Ausschluss der Bestimmungen des Kollisionsrechts.
          </p>
          <p>
            Zur Entscheidung von Streitigkeiten aus und im Zusammenhang mit der
            vorliegenden Vereinbarung einschließlich der Frage des gültigen
            Zustandekommens, des Bestandes oder Nichtbestandes und einer
            allfälligen Anfechtung dieser Vereinbarung ist ausschließlich das
            sachlich zuständige Gericht in Graz zuständig.
          </p>

          <h2>Bedingungen und Änderungen</h2>
          <p>
            Die über die Website abgeschlossene Vereinbarung samt diesen
            Bedingungen zusammen mit der von Userbrain bereitgestellten
            Datenschutzrichtlinie stellt die vollständige und ausschließliche
            Vereinbarung zwischen den Parteien in Bezug auf den hierin
            enthaltenen Gegenstand dar und ersetzt alle vorherigen oder
            gleichzeitigen schriftlichen oder mündlichen Vereinbarungen oder
            Vereinbarungen in Bezug auf ihren Gegenstand. Jede von Ihnen
            allenfalls gewünschte Änderung oder Ergänzung einer Bestimmung
            dieser Vereinbarung ist nur wirksam, wenn sie schriftlich erfolgt
            und von einem ordnungsgemäß bevollmächtigten Vertreter jeder Partei
            unterzeichnet wird, also mit uns explizit, schriftlich und
            firmenmäßig vereinbart wurde.
          </p>
          <p>
            Wir können diese Bedingungen jederzeit ändern. Wenn wir diese
            Bedingungen ändern, aktualisieren wir diese Seite und geben das
            Datum der letzten Änderung an. Wir informieren Sie vor der Änderung
            über die Änderung mittels E-Mails sowie über Ihre
            Reaktionsmöglichkeiten und deren Folgen: Die Änderung tritt 30 Tage
            nach Versendung der Verständigung über die Änderung in Kraft, es sei
            denn, es erfolgt vor Ablauf dieser Frist ein schriftlicher
            Widerspruch, was zur Vertragsauflösung führt. Wenn Sie somit nicht
            rechtzeitig bzw formgerecht widersprechen, stimmen Sie der Änderung
            zu. Wenn Sie rechtzeitig und formgerecht widersprechen, gilt der
            Widerspruch als Kündigung mit den unter diesem Punkt angeführten
            Folgen.
          </p>

          <h2>Kontakt</h2>
          <p>
            Wenn Sie Fragen zu diesen Bedingungen haben, kontaktieren Sie uns
            bitte unter{" "}
            <a href={"mailto:support@userbrain.com"}>support@userbrain.com</a>.
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default Terms;
